import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { localStorageKeys } from '@/consts';

type TenantStore = {
  tenant: string;
  setTenant: (tenant: string) => void;
};

export const tenantStore = create(
  persist<TenantStore>(
    (set) => ({
      tenant: '',
      setTenant: (tenant: string) => set({ tenant }),
    }),
    { name: localStorageKeys.TENANT, version: 0 },
  ),
);
