import { localStorageKeys } from '@/consts';
import { Auth0Client, User, createAuth0Client } from '@auth0/auth0-spa-js';

let auth0Client: Auth0Client | null = null;

type Auth0User = User & {
  pm: {
    urn: string;
    tenant: string;
    roles: string[];
  };
};

const getClient = async (): Promise<Auth0Client> => {
  if (auth0Client) return auth0Client;
  auth0Client = await createAuth0Client({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    cacheLocation: 'localstorage',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    },
  });
  return auth0Client;
};

export const isAuthenticated = async () => (await getClient()).isAuthenticated();

export const getAccessToken = async () => {
  const client = await getClient();
  return client.getTokenSilently();
};

export const redirectToLogin = async () => {
  const client = await getClient();
  return client.loginWithRedirect({
    appState: { target: window.location.pathname },
    authorizationParams: {
      redirect_uri: window.location.origin,
    },
  });
};

export const logout = async () => {
  localStorage.removeItem(localStorageKeys.TENANT);
  localStorage.removeItem(localStorageKeys.TENDERING);

  const client = await getClient();
  return client.logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  });
};

export const hasLoginRedirect = () => {
  const query = window.location.search;
  return query.includes('code=') && query.includes('state=');
};

export const handleRedirectCallback = async () => {
  localStorage.removeItem(localStorageKeys.TENANT);
  localStorage.removeItem(localStorageKeys.TENDERING);

  const client = await getClient();
  const redirect = await client.handleRedirectCallback<{ target: string }>();
  localStorage.setItem('redirectTarget', redirect.appState?.target ?? '/');
};

export const getUser = async (): Promise<Auth0User> => {
  const client = await getClient();

  const user = await client.getUser<Auth0User>();
  if (!user) throw new Error('getUser failed: Not logged in');
  return {
    ...user,
  };
};

export const guardRoute = async (): Promise<'LOGGED_IN' | 'LOGIN_FAILED' | 'NEEDS_LOGIN'> => {
  try {
    if (await isAuthenticated()) return 'LOGGED_IN';

    if (hasLoginRedirect()) {
      try {
        await handleRedirectCallback();
        return 'LOGGED_IN';
      } catch (_) {
        return 'LOGIN_FAILED';
      }
    }
  } catch (_) {
    // nothing to catch
  }
  return 'NEEDS_LOGIN';
};
